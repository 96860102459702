import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login') {
    return next();
    /*
    const thetoken = await getIdToken(auth.currentUser);
    */
  }
  return next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
