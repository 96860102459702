
import Vue from 'vue';
import {
  mdbContainer,
  mdbNavbar,
  mdbNavbarBrand,
  mdbBtnFixed, mdbProgress,
} from 'mdbvue';
import AudioVisual from 'vue2-audio-visual';
import { VueEllipseProgress } from 'vue-ellipse-progress';
import axios from 'axios';

Vue.use(AudioVisual);

declare global {
  interface Window { // ⚠️ notice that "Window" is capitalized here
    hello: any;
    webkitSpeechRecognition: any;
    vegavoiceaudio: any;
  }
}
export default Vue.extend({
  name: 'homepage',
  components: {
    mdbContainer,
    mdbNavbar,
    mdbNavbarBrand,
    mdbBtnFixed,
    mdbProgress,
    VueEllipseProgress,
  },
  data() {
    return {
      recogcontainer: {} as any,
      recognizing: false,
      final_transcript: '',
      partial_transcript: '',
      waitingforairesponse: false,
      media: {} as any,
      lastmp3received: '',
      aipayload: {
        model: 'gpt-3.5-turbo',
        temperature: 0.7,
        messages: [] as any,
      },
      responsemediastream: null as any,
      showresponseanimation: false,
    };
  },
  computed: {
    vegathinking():boolean {
      return !this.recognizing && this.waitingforairesponse;
    },
    lastvegaresponse():string {
      if (this.aipayload.messages.length && this.aipayload.messages.at(-1).role === 'assistant') {
        return this.aipayload.messages.at(-1).content;
      }
      return '';
    },
  },
  mounted() {
    if ('webkitSpeechRecognition' in window) {
      const WebkitSpeechRecognition = window.webkitSpeechRecognition;
      this.recogcontainer.recognitionengine = new WebkitSpeechRecognition();
      this.recogcontainer.recognitionengine.continuous = true;
      this.recogcontainer.recognitionengine.lang = 'fr_CA';
      this.recogcontainer.recognitionengine.interimResults = true;
      this.recogcontainer.recognitionengine.onstart = () => {
        this.recognizing = true;
      };
      this.recogcontainer.recognitionengine.onresult = (event:any) => {
        this.partial_transcript = '';
        let foundfinal = false;
        for (let i = event.resultIndex; i < event.results.length; i += 1) {
          if (event.results[i].isFinal) {
            this.final_transcript += event.results[i][0].transcript;
            foundfinal = true;
          } else {
            this.partial_transcript += event.results[i][0].transcript;
          }
        }
        if (foundfinal) {
          this.recogcontainer.recognitionengine.stop();
          this.recognizing = false;
        }
      };
      this.recogcontainer.recognitionengine.onerror = (event:any) => {
        console.log('ERROR');
        console.log(event);
      };
      this.recogcontainer.recognitionengine.onend = (event:any) => {
        this.recognizing = false;
        this.waitingforairesponse = true;
        this.sendToAi();
      };
    }
    window.vegavoiceaudio.onended = () => { this.showresponseanimation = false; };
  },
  methods: {
    startrecording() {
      if (!this.media.media) {
        const constraints = { audio: true, video: false };
        navigator.mediaDevices.getUserMedia(constraints).then((media) => {
          this.media.media = media;
        });
      }
      if (this.recognizing) {
        this.recogcontainer.recognitionengine.stop();
        this.recognizing = false;
        return;
      }
      this.final_transcript = '';
      this.partial_transcript = '';
      this.recogcontainer.recognitionengine.start();
      this.lastmp3received = '';
      this.showresponseanimation = false;
    },
    sendToAi() {
      if (this.final_transcript.length) {
        this.aipayload.messages.push({
          role: 'user',
          content: this.final_transcript,
        });
        const headers:any = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        axios.post('https://msgs2gpt2voice-77uzsgbt3q-nn.a.run.app', { messages: this.aipayload.messages }, headers).then((response) => {
          this.waitingforairesponse = false;
          this.aipayload.messages.push({
            role: response.data.responserole,
            content: response.data.responseastxt,
          });
          this.lastmp3received = response.data.mp3link.at(0);
          this.$nextTick((a) => {
            this.showresponseanimation = true;
            window.vegavoiceaudio.pause();
            window.vegavoiceaudio.src = this.lastmp3received;
            window.vegavoiceaudio.currentTime = 0;
            window.vegavoiceaudio.play();
          });
        });
      }
    },
  },
});
